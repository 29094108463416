import { SvgIcon, SvgIconProps } from '@mui/material';

export const BooksIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path
                d="M3.01474 2.92388C3.01474 4.30079 2.97021 14.2216 2.98649 15.4017C3.0149 17.4606 3.77928 18.5489 5.67032 18.5489H6.56501M6.56501 7.24475C6.56501 8.35764 6.56501 15.1445 6.56501 18.5489M6.56501 18.5489C6.56501 19.3566 6.56501 19.6418 6.56501 19.9058C6.56501 21.6144 7.74932 22.9747 9.73738 22.9747C11.7254 22.9747 19.6862 22.9747 20.4814 22.9747C21.2767 22.9747 21.3761 23.0731 21.3761 21.9912C21.3761 20.9093 21.3761 10.9595 21.3761 10.1727C21.3761 9.38589 21.3761 9.18919 20.4814 9.18919C19.5868 9.18919 10.1695 9.21364 8.67843 9.21364C5.87999 9.21364 5.91216 5.09925 8.67843 5.09925H19.0902C18.1955 5.09925 6.58903 5.09925 5.09798 5.09925C2.29955 5.09925 2.33171 0.984863 5.09798 0.984863H16.6451"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinejoin="round"
                strokeLinecap="round"
                fill="transparent"
            />
        </SvgIcon>
    );
};

export default BooksIcon;
